// src/components/Introduction.js
import React from 'react';
import './Introduction.css'; // CSS 파일을 임포트합니다.

function Introduction() {
  return (
    <div className="introduction">
      <h2>안녕하세요!</h2>
      <p>스피치포올의 홈페이지에 오신 것을 환영합니다.</p>
    </div>
  );
}

export default Introduction;